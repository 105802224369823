// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-domain-page-templates-playlist-tsx": () => import("./../../../src/components/domain/page-templates/playlist.tsx" /* webpackChunkName: "component---src-components-domain-page-templates-playlist-tsx" */),
  "component---src-components-domain-page-templates-video-tsx": () => import("./../../../src/components/domain/page-templates/video.tsx" /* webpackChunkName: "component---src-components-domain-page-templates-video-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-archive-index-tsx": () => import("./../../../src/pages/archive/index.tsx" /* webpackChunkName: "component---src-pages-archive-index-tsx" */),
  "component---src-pages-archive-playlists-tsx": () => import("./../../../src/pages/archive/playlists.tsx" /* webpackChunkName: "component---src-pages-archive-playlists-tsx" */),
  "component---src-pages-archive-watch-tsx": () => import("./../../../src/pages/archive/watch.tsx" /* webpackChunkName: "component---src-pages-archive-watch-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

