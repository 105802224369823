module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"./src/gatsby"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Devcon","short_name":"Devcon","description":"The annual conference for all Ethereum developers, researchers, thinkers, and makers.","lang":"en","start_url":"/","background_color":"#fff","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/images/devcon-icon.png","localize":[{"start_url":"/es/","lang":"es","name":"Devcon","short_name":"Devcon","description":"La conferencia anual para todos los desarrolladores, investigadores, pensadores y creadores de Ethereum."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0a0ade96d3e0c6ad286289ee8fa46527"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"24","matomoUrl":"https://ethereumfoundation.matomo.cloud/","siteUrl":"https://archive.devcon.org"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/devcon-archive/src/content/i18n","languages":["en","es"],"defaultLanguage":"en","redirect":false},
    }]
